import Vue from 'vue'
import App from './App.vue'
import router from './index'
import store from './store/index'
import '@/assets/css/global.css'
import ElementUI from 'element-ui'; //全局引入element
import 'element-ui/lib/theme-chalk/index.css'; //全局引入element的样式
import '@/assets/css/bootstrap5.min.css'
import {addLog} from "@/api/util";

Vue.config.productionTip = false
Vue.use(ElementUI);     //全局注入element

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


// 全局捕获异常
Vue.config.errorHandler = function (err, vm, info) {
    // 处理异常，例如发送到后端服务器
    console.error('捕获到全局异常：', err, info);
    addLog(info)
};

// 捕获未处理的Promise异常
window.addEventListener('unhandledrejection', (event) => {
    console.error('捕获到未处理的Promise异常：', event.reason);
    addLog(event.reason)
});