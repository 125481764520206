import {postJson} from "@/api/http";
import {logCreate} from "@/api/api";

export function date2String_YMD_HMS(date) {
    let char = '-';
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
    }
    return year + char + month + char + strDate + " " + hours + ":" + minutes + ":" + seconds;
}

export function getDate(date) {
    let char = '-';
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
    }
    return year + char + month + char + strDate;
}

export function getDateStart(date) {
    return getDate(date) + " " + "00:00:00"
}

export function getDateEnd(date) {
    return getDate(date) + " " + "23:59:59"
}

export function addLog(title) {
    postJson(logCreate, {}, {
        type: '001',
        title: title
    }).then(r => {
        console.log(r)
    })
}